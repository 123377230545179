import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styles from './BottomMenu.scss';

class BottomMenu extends Component {

	static propTypes = {
		className: PropTypes.string,
		undo: PropTypes.func,
		redo: PropTypes.func,
		restart: PropTypes.func,
		canUndo:  PropTypes.bool,
		canRedo: PropTypes.bool
	};

	constructor(props) {
		super();

		this.props = props;
		this.state = {
			open: false
		};
	}

	toggleMenu = () => {
		console.log('Toggle menu', this.state.open);
		this.setState({open: !this.state.open});
	}

	render() {
		const className = this.props.className ? `${styles.content} ${this.props.className}` : styles.content;
		return (
			<div className={className}>

				<ul className={`${styles.slideMenu} ${this.state.open ? styles.slideMenuOpen : null}`}>
					<li><button onClick={this.props.restart}><img src={require('./croquet.svg')} alt="croquet" />New Game</button></li>
				</ul>

				<div className={styles.baseMenu}>
					<div className={styles.section}>
						{this.props.canUndo
							?  <button onClick={this.props.undo} disabled={!this.props.canUndo}><img src={require('./undo.svg')} alt="undo" /><span>Undo</span></button>
							: null
						}
					</div>
					<div className={styles.section}>
						<button onClick={this.toggleMenu}><img src={require('./menu.svg')} alt="open  menu" /><span>Menu</span></button>
					</div>
					<div className={styles.section}>
						{this.props.canRedo
							?  <button onClick={this.props.redo} disabled={!this.props.canRedo}><img src={require('./redo.svg')} alt="redo" /><span>Redo</span></button>
							: null
						}
					</div>
				</div>
			</div>
		);
	}

}

export default BottomMenu;
