/**
* RootReducer.js
* The root reducer to combine all other reducers used in the application
**/

import {combineReducers} from 'redux';
import gameReducer from './GameReducer';

export default combineReducers({
	game: gameReducer
});
