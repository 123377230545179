import {render} from 'react-dom';
import React from 'react';
import {BrowserRouter} from 'react-router-dom';
import {Provider} from 'react-redux';
import getStore from './redux/store';
import App from './components/App';
import config from './config/general';

const appContainer = document.getElementById('app');

getStore()
	.then((store) => {
		const app = <Provider store={store}><BrowserRouter><App basePath={config.basePath} /></BrowserRouter></Provider>;
		render(app, appContainer);
	});

let wakeLock = undefined;
async function startWakeLock() {
	try {
		wakeLock = await navigator.wakeLock.request();

		wakeLock.addEventListener('release', () => {
			wakeLock = undefined;
		});
	} catch (err) {
		console.error(`${err.name} error setting wake lock: ${err.message}`);
	}
}

startWakeLock();
document.addEventListener('visibilitychange', async () => {
	if(!wakeLock && document.visibilityState === 'visible') {
		await startWakeLock();
	}
});
