import {connect} from 'react-redux';
import Game from '../components/Game';
import {turns, calculateScore} from '../lib/rules';
import getStore from '../redux/store';
import passHoopAction from '../redux/actions/PassHoopAction';
import addRoquetAction from '../redux/actions/AddRoquetAction';
import shotTakenAction from '../redux/actions/ShotTakenAction';

const store = getStore();

const addRoquet = (ball, colour) => {
	getStore().then((store) => {
		store.dispatch(addRoquetAction({ball, target: colour}));
	});
};

const addHoop = (ball) => {
	console.log('go add hoop');
	getStore().then((store) => {
		console.log('Add hoop');
		store.dispatch(passHoopAction({ball}));
	});
};

const nextShot = () => {
	getStore().then((store) => {
		//const state = store.getState();
		//const turn = state.game.present.get('current');
		/*
		const current = turns.length > turn
			? turns[state.game.present.get('current')]
			: turns[0];
			*/

		store.dispatch(shotTakenAction({}));
	});
};

const mapStateToProps = (state) => {
	const turn = state.game.present.get('current');
	const current = turns.length > turn
		? turns[state.game.present.get('current')]
		: turns[0];

	const game = Object.assign(
		{},
		state.game.present.toJS(),
		{current}
	);

	const thisTurn = Object.assign(
		{},
		state.game.present.getIn(['balls', current]).toJS(),
		{ball: current}
	);

	return {
		turn: thisTurn,
		game,
		addRoquet,
		addHoop,
		nextShot
	};
};


const ReduxContainer = connect(mapStateToProps)(Game);

export default ReduxContainer;
