import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styles from './Game.scss';
import BottomMenu from '../../containers/BottomMenuContainer';
import {turns, numHoops} from '../../lib/rules';
import PostSvg from './post-svg';

const colours = ['blue', 'black', 'red', 'yellow'];

const getClasses = (classList) => {
	return classList
		.map((className) => styles[className])
		.filter((className) => !!className)
		.join(' ');
}


class Game extends Component {

	static propTypes = {
		turn: PropTypes.shape({
			ball: PropTypes.string,
			player: PropTypes.string,
			shots: PropTypes.arrayOf(PropTypes.string),
			hoop: PropTypes.number,
			roquets: PropTypes.arrayOf(PropTypes.string)
		}),
		game: PropTypes.shape({
			current: PropTypes.string,
			balls: PropTypes.shape({
				blue: PropTypes.shape({
					hoop: PropTypes.number,
					roquet: PropTypes.arrayOf(PropTypes.string)
				}),
				black: PropTypes.shape({
					hoop: PropTypes.number,
					roquet: PropTypes.arrayOf(PropTypes.string)
				}),
				red: PropTypes.shape({
					hoop: PropTypes.number,
				roquet: PropTypes.arrayOf(PropTypes.string)
				}),
				yellow: PropTypes.shape({
					hoop: PropTypes.number,
					roquet: PropTypes.arrayOf(PropTypes.string)
				})
			})
		}),
		addRoquet: PropTypes.func.isRequired,
		addHoop: PropTypes.func.isRequired,
		nextShot: PropTypes.func.isRequired
	};

	constructor(props) {
		super();

		this.props = props;
	}

	addRoquet(colour) {
		this.props.addRoquet(colour);
		this.setState({showRoquetPrompt: false});
	}

	render() {
		return <div className={styles.game}>
			<div><h1>Croquet Score</h1></div>
			{turns.map((ball, index) => <BallRow
				ball={ball}
				hoop={this.props.game.balls[ball].hoop}
				active={this.props.turn.ball}
				roquets={this.props.game.balls[ball].roquets}
				nextShot={this.props.nextShot}
				addHoop={this.props.addHoop}
				addRoquet={this.props.addRoquet}
				key={index}
			/>)}
			<BottomMenu />
		</div>
	}

}

function BallRow(props) {
	const {ball, hoop, active, roquets, nextShot, addHoop, addRoquet} = props;

	const isActive = ball === active;

	console.log({ball, hoop, numHoops});
	const isCompleted = hoop > numHoops;

	const addHoopEvt = (evt) => {
		evt.preventDefault();
		addHoop(ball);
	};

	const addRoquetEvt = (evt) => {
		evt.preventDefault();
		addRoquet(active, ball);
	};

	const thisHoop = hoop >= numHoops
		//> <PostSvg/>
		? <span>Pin</span>
		: <span>{hoop + 1}</span>;

	return <div className={getClasses(['player', `player--${ball}`, isActive ? 'player--active' : 'player-inactive'])} >
				<div className={styles.hoop}>{!isCompleted && <button onClick={addHoopEvt}>Run Hoop</button>}</div>
				<div className={styles.ball}>
					{ isActive
						? <div className={styles.score}>{thisHoop}</div>
						: <button onClick={addRoquetEvt}>{thisHoop}</button>
					}
				</div>
				<div className={styles.roquets}>
						<h2>Roquets</h2>
						<ul className={styles.roquetList}>
							{roquets.map((roquet, index) => (<li key={index} className={styles[`roquet--${roquet}`]}>{roquet}</li>))}
						</ul>
				</div>
				<div className={styles.nextBtn}>
					{isActive && <button onClick={nextShot}><span>End turn</span></button>}
				</div>
		</div>;
}


export default Game;
