/**
* GameReducer.js
* Redux Reducer for Game
**/

import actionTypes from '../actionTypes';
import Immutable from 'immutable';
import undoable, {distinctState} from 'redux-undo';
import {numHoops, turns, roquetShots, hoopShots} from '../../lib/rules';

const playerState = {
	player: '',
	shots: ['primary'],
	hoop: 0,
	roquets: []
};

const baseState = {
	current: 0,
	balls: {
		blue: Object.assign({}, playerState),
		yellow: Object.assign({}, playerState),
		black: Object.assign({}, playerState),
		red: Object.assign({}, playerState)
	}
};

function getCompleted(state) {
	return turns.map((ball) => state.getIn(['balls', ball, 'hoop']) > numHoops);
}

function handlePassHoop(state, data) {
	let currState = state;
	console.log({currState});
	const ball = data.ball || turns[state.get('current')];

	let hoop = state.getIn(['balls', ball, 'hoop']) + 1;
	console.log({hoop, numHoops});
	if(hoop > numHoops + 1) {
		hoop = numHoops + 1;
	}

	if(hoop === numHoops + 1) {
		currState = handleShotTaken(state, data);
	}

	return currState
		.setIn(['balls', ball, 'hoop'], hoop)
		.setIn(['balls', ball, 'roquets'], Immutable.fromJS(playerState.roquets));
}

function handleAddRoquet(state, data) {
	const ball = data.ball || turns[state.getIn(['balls', 'ball'])];
	const target = data.target;

	const currentRoquets = state.getIn(['balls', ball, 'roquets']);

	if(currentRoquets.contains(target)) {
		return state;
	}

	const shots = state.getIn(['balls', ball, 'shots'])
		.concat(roquetShots);

	return state
		.setIn(['balls', ball, 'roquets'], currentRoquets.push(target))
		.setIn(['balls', ball, 'shots'], shots);
}

function handleShotTaken(state, data) {
	console.log('shot taken state', state.toJS());
	let turn = state.get('current');
	turn++;
	if(turn >= turns.length) {
		turn = 0;
	}
	const completed = getCompleted(state);
	console.log('complete', completed);
	while(completed[turn]) {
		turn++;
		if(turn >= turns.length) {
			turn = 0;
		}
	}

	return state
		.set('current', turn);
}

function handleRestart(state, data) {
	return Immutable.fromJS(baseState);
}

function setGameReducer(state, action) {
	let currState = state || Immutable.fromJS(baseState);
	if(!Immutable.isImmutable(currState)) {
		currState = Immutable.fromJS(currState);
	}

	switch(action.type) {
	case actionTypes.passHoop:
		return handlePassHoop(currState, action.payload);
	case actionTypes.addRoquet:
		return handleAddRoquet(currState, action.payload);
	case actionTypes.shotTaken:
		return handleShotTaken(currState, action.payload);
	case actionTypes.restart:
		return handleRestart(currState, action.payload);
	default:
		return currState;
	}
}

const undoableGame = undoable(setGameReducer, {limit: 15, neverSkipReducer: true});

export default undoableGame;
