import Immutable from 'immutable';

function convertImmutable(toConvert) {

	if(!toConvert) {
		// Ignore null values
		return toConvert;
	}

	if(Immutable.isImmutable(toConvert)) { 
		return toConvert.toJS();
	}

	if(Array.isArray(toConvert)) {
		return toConvert.map((value) => convertImmutable(value));
	}

	if(typeof toConvert === 'object') {
		const converted = {};
		Object.keys(toConvert).forEach((key) => {
			converted[key] = convertImmutable(toConvert[key]);
		});
		return converted;
	}

	return toConvert;
}

export default convertImmutable;
