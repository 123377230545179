/**
* store.js
* Redux store definiation
**/
import 'regenerator-runtime/runtime';
import {createStore, applyMiddleware} from 'redux';
import reducer from './reducers/RootReducer';
import getPersistMiddleware from './reducers/persist-middleware';
import {get} from 'idb-keyval';

const dataToPersist =  {'game': 'game'};

let loadPromise = undefined;

function getStore() {
	if(loadPromise) {
		return loadPromise;
	}

	loadPromise = new Promise((resolve, reject) => {

		let preloadedState = {};
		console.log('Window', typeof window);
		console.log('Process', typeof process);
		if(typeof window !== 'undefined') {
			preloadedState = window.__PRELOADED_STATE__;
			console.log('Load from storage');

			const persistOptions = {dataToPersist};

			// Override with local data
			const loadPromise = Promise.all(Object.keys(dataToPersist).map((key) => get(key).then((data) => {
				if(!data) {
					console.log(`No data to skipping key ${key}`);
					return;
				}

				console.log(`Data for key ${key}`, data);

				const dataPath = dataToPersist[key];
				if(!preloadedState) {
					preloadedState = {};
				}
				let currObj = preloadedState;
				dataPath.split('.').forEach((currPath, index, arr) => {
					console.log(`Curr object for ${currPath}`, currObj);
					console.log('Preloaded state', preloadedState);
					console.log('arr', arr);
					if(index < arr.length - 1 && typeof currObj[currPath] === 'undefined') {
						// Create any objects required 
						currObj[currPath] = {};
					} else if(index === arr.length - 1) {
						// Place data in the preloaded state
						currObj[currPath] = data;
					}
					// Keep walking down the path
					currObj = currObj[currPath];
				});
			})));

			loadPromise.then(() => {
				console.log('Create store', preloadedState);
				const store = createStore(reducer, preloadedState, applyMiddleware(getPersistMiddleware(persistOptions)));
				console.log('state', store.getState());
				resolve(store);
			})
				.catch((err) => {
					console.error('Error loading state', err);
				});
		} else {
			// There is no persistance or preloaded state on the server
			resolve(createStore(reducer));
		}
	});

	return loadPromise;
}

export default getStore;

