import Immutable from 'immutable';
import ric from 'ric-shim';
import {set} from 'idb-keyval';
import convertImmutable from '../../lib/convert-immutable';

// https://read.reduxbook.com/markdown/part2/12-clientside-persistance.html


const getPersistMiddleware = (options = {}) => store => next => action => {
	let {dataToPersist} = options;
	if(!dataToPersist) {
		console.error('No persistance data set', options);
		dataToPersist = {};
	}

	const result = next(action);

	ric(() => {
		const appState = store.getState();

		Object.keys(dataToPersist).forEach((key) => {
			let data = dataToPersist[key]
				.split('.')
				.reduce(
					//(acc, curr) => Immutable.isImmutable(acc[curr]) ? acc[curr].toJS() : acc[curr],
					(acc, curr) => convertImmutable(acc[curr]),
					appState
				);
			set(key, data);
		});
	});
	return result;
};

export default getPersistMiddleware;
