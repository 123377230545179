import {connect} from 'react-redux';
import {ActionCreators as UndoActionCreators} from 'redux-undo'
import BottomMenu from '../components/BottomMenu';
import restartAction from '../redux/actions/RestartAction';

const mapStateToProps = state => {
  return {
    canUndo: state.game.past.length > 0,
    canRedo: state.game.future.length > 0
  }
}
const mapDispatchToProps = dispatch => {
  return {
    undo: () => dispatch(UndoActionCreators.undo()),
    redo: () => dispatch(UndoActionCreators.redo()),
		restart: () => dispatch(restartAction())
  }
}

const BottomMenuContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(BottomMenu)

export default BottomMenuContainer;
