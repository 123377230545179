/**
* actionTypes.js
* Define global action strings for redux reducers
**/

export default {
	setDummy: 'setDummy',
	passHoop: 'passHoop',
	addRoquet: 'addRoquet',
	shotTaken: 'shotTaken',
	restart: 'restart'
};
