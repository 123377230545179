
const hoopCount = 6;
const turns = ['blue', 'red', 'black', 'yellow'];
const turnIndexes = {
	blue: 0,
	red: 1,
	black: 2,
	yellow: 3
};

const roquetShots = ['roquet', 'follow-on'];
const hoopShots = ['hoop-shot'];
const shotTitles = {
	primary: 'Primary',
	roquet: 'Roquet',
	'follow-on': 'Follow on',
	'hoop-shot': 'Hoop Shot'
};

function calculateScore(hoops) {
	if(hoops < hoopCount + 1) {
		return hoops * 2;
	}

	return hoopCount * 2 + 1;
}

// For some bizzare reason it won't run if I export hoopCount
// but if we change the name it works
// Probably worth upgrading the build tools
const numHoops = hoopCount;


export {
	numHoops,
	turns,
	turnIndexes,
	roquetShots,
	hoopShots,
	shotTitles,
	calculateScore
};

